import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import styles from './DataSamples.module.scss'

const samplesList = [
  {
    name: 'by Category',
    category: 'since 2000',
    url: './podcastdata/by_category_since_2000.tsv',
    delimiter: '\t',
    sourceName: 'PodcastIndex + Ad Aures',
    sourceURL:
      'https://podcastindex.org/',
  },
  {
    name: 'by Category',
    category: 'last month',
    url: './podcastdata/by_category_last_month.tsv',
    delimiter: '\t',
    sourceName: 'PodcastIndex + Ad Aures',
    sourceURL:
      'https://podcastindex.org/',
  },  
  {
    name: 'by Provider',
    category: 'since 2000',
    url: './podcastdata/by_provider_since_2000.tsv',
    delimiter: '\t',
    sourceName: 'PodcastIndex + Ad Aures',
    sourceURL:
      'https://podcastindex.org/',
  },
  {
    name: 'by Provider',
    category: 'last month',
    url: './podcastdata/by_provider_last_month.tsv',
    delimiter: '\t',
    sourceName: 'PodcastIndex + Ad Aures',
    sourceURL:
      'https://podcastindex.org/',
  },  
  {
    name: 'by Language',
    category: 'since 2000',
    url: './podcastdata/by_language_since_2000.tsv',
    delimiter: '\t',
    sourceName: 'PodcastIndex + Ad Aures',
    sourceURL:
      'https://podcastindex.org/',
  },  
  {
    name: 'by Language',
    category: 'last month',
    url: './podcastdata/by_language_last_month.tsv',
    delimiter: '\t',
    sourceName: 'PodcastIndex + Ad Aures',
    sourceURL:
      'https://podcastindex.org/',
  },    

]
export default function DataSamples({ onSampleReady, setLoadingError }) {
  const select = async (sample) => {
    const { delimiter, url } = sample
    let response
    try {
      response = await fetch(url)
    } catch (e) {
      setLoadingError('Loading error. ' + e.message)
      return
    }
    const text = await response.text()
    onSampleReady(text, delimiter)
    setLoadingError(null)
  }
  return (
    <Row>
      {samplesList
        // sort by category name
        //.sort((a, b) => a.name.localeCompare(b.name))
        .map((d, i) => {
          return (
            <Col xs={6} lg={4} xl={3} key={i} style={{ marginBottom: 15 }}>
              <Card className="cursor-pointer h-100">
                <Card.Body
                  onClick={() => {
                    select(d)
                  }}
                  className="d-flex flex-column"
                >
                  <Card.Title className="">
                    <h2 className="">{d.name}</h2>
                    <h4 className="m-0">{d.category}</h4>
                  </Card.Title>
                </Card.Body>
                <a
                  href={d.sourceURL}
                  className={[styles['dataset-source']].join(' ')}
                >
                  Source: {d.sourceName}
                </a>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}
