import React from 'react'
import styles from './Footer.module.scss'
import { Row, Col, Container } from 'react-bootstrap'
import { BsFillEnvelopeFill/*, BsBarChartFill*/ } from 'react-icons/bs'
import { FaTwitter/*, FaGithub*/ } from 'react-icons/fa'

// #TODO add commit hash
// const commitHash = process.env.REACT_APP_VERSION || 'dev'

export default function Footer(props) {
  return (
    <Container fluid style={{ backgroundColor: '#111' }}>
      <Container className={styles.footer}>
        <Row>
          <Col xs={6} sm={{ span: 5, order: 1 }} lg={{ span: 3, order: 1 }}>
            <p className="Xsmall">
              · <a
                href="http://podcastdata.org/"
                target="_blank"
                rel="noopener noreferrer"
              >Podcastdata.org
	  </a> is an open data project by <a
                href="http://adaures.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ad Aures
              </a> using <a
                href="http://podcastindex.org/"
                target="_blank"
                rel="noopener noreferrer"
              >PodcastIndex
	  </a> and <a
                href="http://rawgraphs.io/"
                target="_blank"
                rel="noopener noreferrer"
              >RAWGraphs
	  </a>.<br />
              · <a href="https://creativecommons.org/licenses/by-sa/4.0/">
                (CC BY-SA 4.0)
              </a> 2023.
            </p>
          </Col>
          <Col xs={6} sm={{ span: 5, order: 3 }} lg={{ span: 3, order: 1 }}>
            <p className="Xsmall">· Last update: 2023-08-03<br />
              · 4,205,339 podcasts<br />
              · 96,544,789 episodes</p>
          </Col>
          {/* <Col xs={6} sm={{span:5, order:3}} lg={{span:3,order:1}}><p className="Xsmall">This <span title={commitHash}>version</span> is intended to be available only for the backers of the crowdfunding campaign.</p></Col> */}
          <Col
            xs={6}
            sm={{ span: 6, offset: 1, order: 2 }}
            md={{ span: 3 }}
            lg={{ offset: 0 }}
            xl={{ span: 2, offset: 2 }}
          >
            <p>
              <BsFillEnvelopeFill /> hello at podcastdata.org
              <br />
              <FaTwitter />{' '}
              <a
                href="https://twitter.com/podcastdata_org"
                target="_blank"
                rel="noopener noreferrer"
              >
                @podcastdata_org
              </a>
            </p>
          </Col>
          <Col
            xs={6}
            sm={{ span: 6, offset: 1, order: 4 }}
            md={{ span: 2 }}
            lg={{ offset: 0 }}
            xl={{ span: 2, offset: 0 }}
          >
            <p>
              <img src="podcastdata.svg" alt="podcastdata.org" title="Podcastdata.org" width="64" height="64" />
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
